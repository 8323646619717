<div class="container" style="height: 100%;">
    <div class="text-center logo"><img class="logo center" src="assets/images/logo_header.png" alt="Repman logo" />
    </div>
    <div class="row justify-content-center text-center cards">
        <div class="card" style="width: 18em;">
            <div class="card-body">
                <h2 class="card-title">Repman S&L</h2>
                <p class="card-text">Sanitarios y Lavamanos</p>
                <a href="/sl" class="btn btn-primary">Ingresar</a>
            </div>
        </div>

        <div class="card" style="width: 18em;">
            <div class="card-body">
                <h2 class="card-title">Repman RV</h2>
                <p class="card-text">Revestimientos</p>
                <a href="/rv" class="btn btn-primary">Ingresar</a>
            </div>
        </div>
    </div>
</div>