// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  END_POINT: "http://api-sl.repman.co/",
  // END_POINT: "http://api-sl.repman.co:82/",
  END_POINT: "http://localhost:3002/",
  END_POINT2: "http://localhost:3003/",
  END_POINT3: "http://localhost:3004/",
  END_POINT4: "http://localhost:3005/",
  END_POINT5: "http://localhost:3004/",
  WEB_API_VERSION: "v1",
  INTEGRACIONSAP_API_VERSION: "v1",
  VERSION: "1.0.21",
  SAP_API_VERSION: "v1",
  REPORT_API_VERSION: "v1",
  CC01: "CC01",
  CC06: "CC06",
  Vacio: "Pendientes",
  Lleno: "Selecccionado",

  // Local storage keys
  TOKEN_USUARIO_KEY: "ut",
  MATERIAL_KEY: "material",
  MATERIAL_SIN_RESTRICCION_KEY: "materialSinRestriccion",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
