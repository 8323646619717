import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Error404Component } from "./components/error404/error404.component";
import { PoliticasPrivacidadComponent } from "./components/politicas-privacidad/politicas-privacidad.component";
import { InicioComponent } from "./components/inicio/inicio.component";

const routes: Routes = [
  { path: "privacidad", component: PoliticasPrivacidadComponent },
  { path: "", redirectTo: "/inicio", pathMatch: "full" },
  { path: "inicio", component: InicioComponent },
  { path: "**", component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
