import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clasificacion'
})
export class ClasificacionPipe implements PipeTransform {

  transform(clasificacion: string): string {
    if (clasificacion == "4") {
      return 'Buena';
    }else if(clasificacion == "3"){
      return 'Cambio de molde'
    } else if (clasificacion == "2") {
      return 'Mala';
    } else if (clasificacion == "1") {
      return 'Vacia';
    } else if (clasificacion == "5") {
      return 'Sin programar';
    }
  }

}
