import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanos'
})
export class BooleanosPipe implements PipeTransform {
  transform(boleano: boolean): string {
    if (boleano == false) {
      return 'NO';
    } else if (boleano == true) {
      return 'SI';
    }
  }

}
