import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "fecha",
})
export class FechaPipe implements PipeTransform {
  transform(fecha: number): string {
    return moment.unix(fecha).format("DD/MM/YYYY");
  }
}
