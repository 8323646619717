import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import {MatButtonModule} from '@angular/material/button';
// angular material
import { MatTableModule } from "@angular/material/table";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import {  MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { ErrorInterceptor } from "./helpers/error/error.interceptor";
import { Error404Component } from "./components/error404/error404.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { EstadoPipe } from "./pipes/estado.pipe";
import { MatTableExporterModule } from "mat-table-exporter";


//draggables
import {DragDropModule} from '@angular/cdk/drag-drop';
import { TipoPipe } from "./pipes/tipo.pipe";
import { MatRadioModule } from "@angular/material/radio";
import { FechaPipe } from "./pipes/fecha.pipe";
import { ProcesoPipe } from "./pipes/proceso.pipe";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { ClasificacionPipe } from './pipes/clasificacion.pipe';
import { PoliticasPrivacidadComponent } from './components/politicas-privacidad/politicas-privacidad.component';
import {MatSelectModule} from '@angular/material/select';
import { BooleanosPipe } from './pipes/booleanos.pipe';
import { InicioComponent } from './components/inicio/inicio.component';
@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    EstadoPipe,
    TipoPipe,
    FechaPipe,
    ProcesoPipe,
    ClasificacionPipe,
    PoliticasPrivacidadComponent,
    BooleanosPipe,
    InicioComponent,
  ],
  imports: [
    DragDropModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatExpansionModule,
    MatSortModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableExporterModule,
    MatTooltipModule,
    MatDialogModule,
    NgbModule,
    MatTableModule,
    // ChartsModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  exports: [DragDropModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
