import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipo'
})
export class TipoPipe implements PipeTransform {

  transform(estado: number): string {
    if (estado == 1) {
      return 'Tunel';
    } else if (estado == 2) {
      return 'Camara';
    }
  }

}
