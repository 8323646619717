import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proceso'
})
export class ProcesoPipe implements PipeTransform {

  transform(estado: number): string {
    if (estado == 1) {
      return 'Formación';
    } else if (estado == 3) {
      return 'Pre-secado';
    } else if (estado == 5) {
      return 'Secado';
    } else if (estado == 6) {
      return 'Devolución';
    } else if (estado == 7) {
      return 'Plaza';
    } else if (estado == 9) {
      return 'Pulido';
    } else if (estado == 11) {
      return 'Esmaltado';
    } else if (estado == 12) {
      return 'Carga horno';
    } else if (estado == 13) {
      return 'Clasificación';
    } else if (estado == 17) {
      return 'Libre';
    }
  }


}
