<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>
<router-outlet></router-outlet>
<div class="navbar">
  <a href="inicio">Inicio</a>
  <a href="privacidad">Política de privacidad</a>
</div>
<p id="app_version">Staging version {{ appVersion }}</p>
