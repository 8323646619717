import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estado'
})
export class EstadoPipe implements PipeTransform {

  transform(estado: boolean): string {
    if (estado == true) {
      return 'Inactivo';
    } else if (estado == false) {
      return 'Activo';
    }
  }

}
