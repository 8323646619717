<div class="container">
    <div class="text-center logo"><img class="logo center" src="assets/images/logo_header.png" alt="Repman logo" />
    </div>
    <div class="row">
        <div class="content">
            <div class="text-center">
                <h1>Política de privacidad</h1>
            </div>
            <p>Última actualización: 03 de septiembre de 2022</p>
            <p>En esta Política de privacidad se describe nuestras políticas y procedimientos sobre la recopilación, el
                uso y la divulgación de Su información cuando utiliza el Servicio y se le informa sobre Sus derechos de
                privacidad y cómo la ley lo protege.</p>
            <p>Usamos Sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la
                recopilación y el uso de información de acuerdo con esta <span class="negrita">Política de
                    privacidad.</span></p>

            <p class="titulo">Interpretación y definiciones</p>
            <p class="subtitulo">Interpretación</p>
            <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes
                condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que
                aparezcan en singular o en plural.</p>
            <p class="subtitulo">Definiciones</p>
            <p>A los efectos de esta Política de Privacidad:</p>
            <p><span class="negrita">Cuenta:</span> significa una Cuenta única creada para que Usted acceda a Nuestro
                Servicio o partes de Nuestro Servicio.</p>
            <p><span class="negrita">Afiliado:</span> significa una entidad que controla, es controlada o está bajo
                control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones,
                participación accionaria u otros valores con derecho a voto para la elección de directores u otra
                autoridad administrativa.</p>
            <p><span class="negrita">Aplicación:</span> significa el programa de software proporcionado por la Compañía
                descargado por Usted en cualquier dispositivo electrónico, llamado Repman o cualquiera de sus módulos:
                Repman S&L, Repman RV, Repman testing II o Repman RV testing</p>
            <p><span class="negrita">La Compañía:</span> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en
                este Acuerdo) se refiere a Electrovan SAS, Calle 34 # 51 – 28, Medellín, Antioquia</p>
            <p><span class="negrita">Las Cookies:</span> son pequeños archivos que un sitio web coloca en su
                computadora, dispositivo móvil o cualquier otro Dispositivo, y que contienen los detalles de su
                historial de navegación en ese sitio web entre sus muchos usos.</p>
            <p><span class="negrita">País:</span> se refiere a Colombia</p>
            <p><span class="negrita">Dispositivo:</span> significa cualquier Dispositivo que pueda acceder al Servicio,
                como una computadora, un teléfono celular o una tableta digital.</p>
            <p><span class="negrita">Los Datos Personales:</span> son cualquier información que se relaciona con un
                individuo identificado o identificable.</p>
            <p><span class="negrita">Servicio:</span> se refiere a la Aplicación, al Sitio web o a ambos.</p>
            <p><span class="negrita">Proveedor de servicios:</span> significa cualquier persona física o jurídica que
                procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por
                la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de la Empresa, realizar
                Servicios relacionados con el Servicio o ayudar a la Empresa a analizar cómo se utiliza el Servicio.</p>
            <p><span class="negrita">Los Datos de uso: </span> se refieren a los datos recopilados automáticamente, ya
                sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la
                duración de una visita a la página).</p>
            <p><span class="negrita">El sitio web: </span> se refiere a <span class="negrita">Repman</span>, accesible
                desde https://repman.co</p>
            <p><span class="negrita">Usted</span> se refiere a la persona que accede o utiliza el Servicio, o la Empresa
                u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.
            </p>

            <p class="titulo">Recopilación y uso de sus datos personales</p>
            <p class="subtitulo">Tipos de datos recopilados</p>
            <p class="subtitulo">Información personal</p>
            <p>Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación
                personal que se puede usar para contactarlo o identificarlo. La información de identificación personal
                puede incluir, entre otros:</p>
            <ul>
                <li>Datos de uso</li>
            </ul>
            <p class="subtitulo">Datos de uso</p>
            <p>Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</p>
            <p>Los Datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo
                (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de Nuestro
                Servicio que visita, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, dispositivo
                único identificadores y otros datos de diagnóstico.</p>
            <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información
                automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación
                única de su dispositivo móvil, la dirección IP de su dispositivo móvil, la versión del Servicio que
                utiliza, su sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores
                únicos de dispositivos y otros datos de diagnóstico.</p>
            <p>También podemos recopilar información que su navegador envía cada vez que visita Nuestro Servicio o
                cuando accede al Servicio a través de un dispositivo móvil.</p>

            <p class="subtitulo">Tecnologías de seguimiento y Cookies</p>
            <p>Usamos Cookies y tecnologías de seguimiento similares para rastrear la actividad en Nuestro Servicio y
                almacenar cierta información. Las tecnologías de seguimiento utilizadas son etiquetas y scripts para
                recopilar y rastrear información y para mejorar y analizar Nuestro Servicio. Las tecnologías que
                utilizamos pueden incluir:</p>

            <ul>
                <li>
                    <span class="negrita">Cookies o Cookies del Navegador:</span> una cookie es un pequeño archivo que
                    se coloca en su dispositivo. Puede indicar a su navegador que rechace todas las Cookies o que
                    indique cuándo se envía una cookie. Sin embargo, si no acepta las Cookies, es posible que no pueda
                    utilizar algunas partes de Nuestro Servicio. A menos que haya ajustado la configuración de su
                    navegador para que rechace las Cookies, Nuestro Servicio puede usar Cookies.
                </li>
            </ul>

            <p>Las Cookies pueden ser Cookies "persistentes" o de "sesión". Las Cookies persistentes permanecen en su
                computadora personal o dispositivo móvil cuando se desconecta, mientras que las Cookies de sesión se
                eliminan tan pronto como cierra su navegador web.</p>
            <p>Utilizamos Cookies de sesión y persistentes para los fines establecidos a continuación:</p>

            <p class="subtitulo">Cookies necesarias/esenciales</p>
            <p><span class="negrita">Tipo:</span> Cookies de sesión</p>
            <p><span class="negrita">Administrado por:</span> Nosotros</p>
            <p><span class="negrita">Propósito:</span> estas Cookies son esenciales para brindarle los Servicios disponibles a través del sitio web y permitirle usar algunas de sus funciones. Ayudan a autenticar a los usuarios y previenen el uso fraudulento de Cuentas de usuario. Sin estas Cookies, no se pueden proporcionar los Servicios que ha solicitado, y solo utilizamos estas Cookies para proporcionarle esos Servicios.</p>

            <p class="subtitulo">Política de Cookies / Aviso de Aceptación de Cookies</p>
            <p><span class="negrita">Tipo:</span> Cookies persistentes</p>
            <p><span class="negrita">Administrado por:</span> Nosotros</p>
            <p><span class="negrita">Finalidad:</span> estas Cookies identifican si los usuarios han aceptado el uso de Cookies en el Sitio Web.</p>

            <p class="subtitulo">Cookies de funcionalidad</p>
            <p><span class="negrita">Tipo:</span> Cookies persistentes</p>
            <p><span class="negrita">Administrado por:</span> Nosotros</p>
            <p><span class="negrita">Propósito:</span> estas Cookies nos permiten recordar las elecciones que hace cuando usa el Sitio Web, como recordar sus datos de inicio de sesión o su preferencia de idioma. El propósito de estas Cookies es brindarle una experiencia más personal y evitar que tenga que volver a ingresar sus preferencias cada vez que use el Sitio Web.</p>

            <p>Para obtener más información sobre las Cookies que utilizamos y sus opciones con respecto a las Cookies,
                visite nuestra Política de Cookies o la sección Cookies de nuestra Política de privacidad.</p>

            <p class="titulo">Uso de sus datos personales</p>
            <p>La Compañía puede utilizar los Datos Personales para los siguientes propósitos:</p>

            <p>
                <span class="negrita">Para proporcionar y mantener Nuestro Servicio:</span> incluso para monitorear el
                uso de
                Nuestro Servicio.
            </p>
            <p>
                <span class="negrita">Para gestionar Su Cuenta: </span> para gestionar Su registro como usuario del
                Servicio.
                Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio
                que están disponibles para Usted como usuario registrado.
            </p>
            <p>
                <span class="negrita">Para la ejecución de un contrato: </span> el desarrollo, cumplimiento y
                realización del
                contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier otro
                contrato con Nosotros a través del Servicio.
            </p>
            <p>
                <span class="negrita">Para contactarlo: </span> para contactarlo por correo electrónico, llamadas
                telefónicas,
                SMS u otras formas equivalentes de comunicación electrónica, como notificaciones automáticas de una
                aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las
                funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad,
                cuando sea necesario o razonable para su implementación.
            </p>
            <p>
                <span class="negrita">Para proporcionarle</span> noticias, ofertas especiales e información general
                sobre otros
                bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó, a menos
                que haya optado por no recibir dicha información.
            </p>
            <p>
                <span class="negrita">Para gestionar Sus solicitudes: </span> Para atender y gestionar Sus solicitudes
                hacia
                Nosotros.
            </p>
            <p>
                <span class="negrita">Para transferencias comerciales: </span> podemos usar Su información para evaluar
                o
                realizar una fusión, venta, reestructuración, reorganización, disolución u otra venta o
                transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como parte de
                una quiebra, liquidación, o procedimiento similar, en el que los Datos Personales que tenemos sobre
                los usuarios de Nuestro Servicio se encuentran entre los activos transferidos.
            </p>
            <p>
                <span class="negrita">Para otros fines: </span> podemos utilizar Su información para otros fines, como
                el
                análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de
                nuestras campañas promocionales y para evaluar y mejorar Nuestro Servicio, productos, servicios,
                marketing y su experiencia.
            </p>

            <p>Podemos compartir Su información personal en las siguientes situaciones:</p>
            <ul>
                <li><span class="negrita">Con Proveedores de Servicios:</span> podemos compartir Su información personal
                    con Proveedores de Servicios para monitorear y analizar el uso de Nuestro Servicio, para
                    contactarlo.</li>
                <li><span class="negrita">Para transferencias comerciales:</span> podemos compartir o transferir Su
                    información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de
                    activos de la Compañía, financiación o adquisición de todo o una parte de Nuestro negocio a otra
                    compañía.</li>
                <li><span class="negrita">Con Afiliados:</span> Podemos compartir Su información con Nuestros Afiliados,
                    en cuyo caso exigiremos a esos Afiliados que respeten esta Política de Privacidad. Los Afiliados
                    incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras
                    empresas que controlamos o que están bajo control común con Nosotros. </li>
                <li><span class="negrita">Con socios comerciales:</span> podemos compartir Su información con Nuestros
                    socios comerciales para ofrecerle ciertos productos, servicios o promociones. </li>
                <li><span class="negrita">Con otros usuarios:</span> cuando comparte información personal o interactúa
                    en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y
                    puede distribuirse públicamente al exterior. </li>
                <li><span class="negrita">Con su consentimiento:</span> podemos divulgar Su información personal para
                    cualquier otro propósito con su consentimiento.</li>
            </ul>

            <p class="titulo">Retención de sus Datos personales</p>
            <p>La Compañía conservará sus Datos Personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>
            <p>La Compañía también conservará los Datos de uso para fines de análisis interno. Los Datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de Nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>

            <p class="titulo">Transferencia de sus Datos personales</p>
            <p>Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de Su estado, provincia, País u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de Su jurisdicción.</p>
            <p>Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su acuerdo con esa transferencia.</p>
            <p>La Compañía tomará todas las medidas razonablemente necesarias para garantizar que Sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus Datos Personales a una organización o País, a menos que existan controles adecuados establecidos, incluida la seguridad de Sus datos y otra información personal.</p>

            <p class="titulo">Divulgación de sus datos personales</p>
            <p class="subtitulo">Transacciones de negocios</p>
            <p>Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus Datos Personales pueden transferirse. Le enviaremos un aviso antes de que sus Datos Personales se transfieran y queden sujetos a una Política de privacidad diferente.</p>
            <p class="subtitulo">Cumplimiento de la ley</p>
            <p>En determinadas circunstancias, es posible que se le solicite a la Compañía que divulgue sus Datos Personales si así lo requiere la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).</p>
            <p class="subtitulo">Otros requisitos legales</p>
            <p>la compañía puede divulgar sus datos Personales de buena fe cuando considere que esta acción es necesaria para lo siguiente:</p>
            <ul><li>Cumplir con una obligación legal.</li>
                <li>Proteger y defender los derechos o propiedad de la compañía.</li>
                <li>Prevenir o investigar posibles irregularidades en relación con el Servicio.</li>
                <li>Proteger la seguridad personal de los Usuarios del Servicio o del público.</li>
                <li>Proteger contra la responsabilidad legal.</li>
            </ul>

            <p class="titulo">Seguridad de sus Datos personales</p>
            <p>La seguridad de sus Datos Personales es importante para Nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.</p>

            <p class="titulo">Privacidad de los niños</p>
            <p>Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 13 años. Si Usted es un padre o tutor y sabe que su hijo nos ha proporcionado Datos Personales, por favor contáctenos. Si nos damos cuenta de que hemos recopilado Datos Personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de Nuestros servidores.</p>
            <p>Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su País requiere el consentimiento de uno de sus padres, podemos solicitar el consentimiento de su padre antes de recopilar y usar esa información.</p>

            <p class="titulo">Enlaces a otros sitios web</p>
            <p>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</p>
            <p>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.</p>

            <p class="titulo">Cambios a esta Política de Privacidad</p>
            <p>Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.</p>
            <p>Le informaremos por correo electrónico y/o un aviso destacado en Nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de privacidad.</p>
            <p>Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.</p>

            <p class="titulo">Contáctenos</p>
            <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos por correo electrónico: comercial@electrovan.co</p>
        </div>
    </div>
</div>